// ** React Imports
import { Suspense, lazy } from "react"
import ReactDOM from "react-dom"

// ** Redux Imports
import { Provider } from "react-redux"
import { store } from "./redux/storeConfig/store"


// ** Intl, CASL & ThemeColors Context
import { ToastProvider } from 'react-toast-notifications'
import { ToastContainer } from 'react-toastify'
import ability from './configs/acl/ability'
import { AbilityContext } from './utility/context/Can'
import { IntlProviderWrapper } from './utility/context/Internationalization'
import { ThemeContext } from './utility/context/ThemeColors'
// import { DockProvider, Dock } from 'react-use-dock'

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Import Apollo Client Config
import { ApolloProvider } from "@apollo/client"
import apolloClient from "./utility/ApolloClient"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/components/prism-jsx.min"
import "prismjs/themes/prism-tomorrow.css"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss"

// ** Core styles
import "react-big-calendar/lib/css/react-big-calendar.css"
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

// ** Service Worker
import * as serviceWorker from "./serviceWorker"
// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ToastProvider>
            <ThemeContext>
              <IntlProviderWrapper>
                
                <LazyApp />
                <ToastContainer newestOnTop />
              </IntlProviderWrapper>
            </ThemeContext>
          </ToastProvider>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
